import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudyaudl.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-audl-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2}
                            height={pageContext.acf.client_logo.media_details.height / 2}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/10/merit-mile-audl-pr-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12 margin-b">
                        <div className="bar"></div>
                    </div>
                    <div className="col-md-6 margin-b">
                        <p>The American Ultimate Disc League (AUDL) was founded in 2012 to introduce and showcase its talented athletes and precision play to a global audience. Ultimate is a fast, flowing, and highly athletic low-contact sport that is played by advancing a disc (frisbee) down a field to score by passing into the end zone. Players cannot run with the disc, and play continues until one team scores.</p>
                        <p>With pinpoint passing and the unique flight of the disc, each game generates many exciting catches and defensive plays that frequently</p>
                    </div>
                    <div className="col-md-6">
                        <p>appear on EPSN’s Sports Center Top 10, Fox Sports, Bleacher Report, and other leading sports shows.</p>
                        <p>The league has 22 teams playing in the US and Canada.  Each team competes in a 12-game regular season schedule, with top teams entering a playoff round and divisional winners advancing to a final four style Championship Weekend. The league’s audience enjoys exciting, affordable,
family-friendly events.</p>
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Challenge</h3>
                            <p>The sport of Ultimate Disc is often confused with the sport of Frisbee golf. Because of this, it was important to educate the media about the American Ultimate Disc League, the history behind the game, its diversity and inclusion initiatives, and how it’s grown to become a sport that will soon be included in the Summer Olympic games. Merit Mile needed to present a strategy to help The AUDL establish itself as the “Ultimate” sport where players execute incredible displays of athleticism while having the utmost respect for each other and
the game.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                            style={{ paddingTop: "2em", paddingBottom: "2em", marginBottom: "1em"}}
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 text-center">
                        <ul class="logoz">
                            <li>
                                <a href="https://www.foxnews.com/sports/audl-commissioner-steve-hall-growing-ultimate-sports-fans-should-come-out" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/fox-news.png" 
                                        className="img-fluid"
                                        alt="Fox News" 
                                        width="115"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.forbes.com/sites/timcasey/2022/06/15/legends-acquires-minority-stake-in-american-ultimate-disc-league/?sh=3ce8902e2c99" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/forbes.png" 
                                        className="img-fluid"
                                        alt="Forbes" 
                                        width="184"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.cbsnews.com/chicago/news/it-is-a-labor-of-love-chicago-union-the-citys-ultimate-disc-league-team-riding-a-wave-of-success/" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/cbs-news-chicago.png" 
                                        className="img-fluid"
                                        alt="CBS News Chicago" 
                                        width="410"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.9news.com/article/sports/colorado-summit-is-putting-local-ultimate-frisbee-on-the-map/73-d918c1ec-cf58-4571-8ffa-e4fbc6f82093" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/9-news.png" 
                                        className="img-fluid"
                                        alt="9 News" 
                                        width="260"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.kare11.com/article/sports/minnesota-wind-chill-seeks-first-ever-playoff-win-saturday-indianapolis-alleycats-audl/89-74c3be48-482d-47a8-9735-816ae4b57d83" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/kare-11.png" 
                                        className="img-fluid"
                                        alt="Kare 11" 
                                        width="180"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://whyy.org/episodes/phoenix-ultimate-frisbee-team-soars-in-south-philly/" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/whyy-pbs-npr.png" 
                                        className="img-fluid"
                                        alt="WHYY PBS npr" 
                                        width="150"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.dmagazine.com/publications/d-magazine/2022/july/the-local-couple-who-bought-a-pro-sports-team/" target="_blank" rel="noopener,noreferrer">
                                    <img 
                                        src="https://www.meritmile.com/uploads/2022/10/d.png" 
                                        className="img-fluid"
                                        alt="D" 
                                        width="100"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <p>With the help of Merit Mile, The AUDL is now inserted into conversations across all markets in the United States. The AUDL has also been featured in a variety of top TV shows, publications, and podcasts.</p>
                    </div>
                    <div className="col-md-6">
                        <p>For the first six months of the AUDL's engagement with Merit Mile, <b>the client averaged $231.17 return on every dollar invested into their public relations program.</b></p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <h4 className="blueTitle">
                            Total Impressions: <b>106,400,251</b><br />
                            Total Ad Equivalency: <b>$4,854,627</b><br />
                            Total ROI: <b>231.17 Monthly</b>
                        </h4>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/10/screens.jpg"
                            className="results-image img-fluid"
                            alt="AUDL PR Results"
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <img
                            src="https://www.meritmile.com/uploads/2022/10/piechart.jpg"
                            className="results-image-two img-fluid"
                            alt="Microsoft Nuance Case Study Results"
                            style={{ marginBottom: "1em", maxWidth: "100%" }}
                        />
                    </div>                    
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/js-thumb.jpg"
                                        alt="John Sternal"
                                        className="img-fluid"
                                    />
                                    <h4>John Sternal</h4>
                                    <p>
                                        Partner, PR,<br />
                                        Social, Research<br />
                                        &nbsp;
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/jb-thumb.jpg"
                                        alt="Justin Baronoff"
                                        className="img-fluid"
                                    />
                                    <h4>Justin Baronoff</h4>
                                    <p>
                                        Public Relations<br />
                                        Account Executive<br />
                                        &nbsp;
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=AUDL%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
